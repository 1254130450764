<template>
  <div>
    <main class="content-1400 page-content">
      <div class="pad-4">
        <div class="pad-8 grid-12">
          <div class="col-sp-12 row-sp-12 mar-bottom-16">
            <h5 class="color-primary f-normal">
              Übersicht
            </h5>
            <h1>Bestellhistorie</h1>
          </div>
          <div class="col-sp-12 col-tb-6 row-sp-12 t-right">
            <div class="field">
              <input type="search" placeholder="Suche.." v-model="searchString">
            </div>
          </div>
          <div class="col-sp-12 col-tb-6 row-sp-12 t-right">
            <button v-if="deleteState == 0" class="button black" @click="deleteState = 1; getOrdersHistorie()">
              Gelöschte Bestellungen
            </button>
            <button v-else class="button primary" @click="deleteState = 0; getOrdersHistorie()">
              Alle Bestellungen
            </button>
          </div>
        </div>
        <div class="pad-8">
          <div class="bgcolor-white pad-24 br-10">
            <div v-if="myorders != 'loading'">
              <div class="grid-12 mar-bottom-16">
                <div class="col-sp-12 col-tb-1 row-sp-12">
                  <h6>ID</h6>
                </div>
                <div class="col-sp-12 col-tb-9 row-sp-12">
                  <h6>Status</h6>
                </div>
                <div class="col-sp-12 col-tb-2 row-sp-12 t-right">
                  <h6>Bestelldetails</h6>
                </div>
              </div>
              <div class="grid-12 mar-bottom-8 br-10 pad-8" style="background-color: #00000005" v-for="order in ordersFiltered" :key="order.id">
                <div class="col-sp-12 col-tb-1 row-sp-12">
                  <p class="color-grey pad-top-4">
                    #{{ order.id }}
                  </p>
                </div>
                <div class="col-sp-12 col-tb-9 row-sp-12">
                  <div v-if="order.status == 0">
                    <span class="label tiny blue">In Freigabe</span>
                    <p class="color-grey mar-left-8" style="font-size: 0.9em; display: inline-block;">
                      Liegt zur Genehmigung bei {{ this.SL(order.id) }}
                    </p>
                  </div>
                  <div v-else-if="order.status == 1">
                    <span class="label tiny green">Genehmigt</span>
                    <p class="color-grey mar-left-8" style="font-size: 0.9em; display: inline-block;">
                      Wurde genehmigt durch {{ order.firstname }} {{ order.lastname }} ({{ order.genehmigeremail }})
                    </p>
                  </div>
                  <div v-else>
                    <span class="label tiny red">Abgelehnt</span>
                    <p class="color-grey mar-left-8" style="font-size: 0.9em; display: inline-block;">
                      Wurde abgelehnt durch {{ order.firstname }} {{ order.lastname }} ({{ order.genehmigeremail }})
                    </p>
                    <p v-if="order.deny_reason" class="color-grey mar-left-8" style="display: inline-block;">
                      <i style="font-size: 0.7em"> [{{ order.deny_reason }}]</i>
                    </p>
                  </div>
                </div>
                <div class="col-sp-12 col-tb-2 row-sp-12 t-right">
                  <button class="button primary tiny" @click="loadDetails(order.id)">
                    Bestelldetails
                  </button>
                  <button class="mar-left-4 button black tiny" v-if="deleteState == 0" @click="deleteOrder(order.id,1)">
                    Bestellung löschen
                  </button>
                  <button class="mar-left-4 button black tiny" v-if="deleteState == 1" @click="deleteOrder(order.id,0)">
                    Wiederherstellen
                  </button>
                </div>
              </div>
              <div v-if="ordersFiltered.length == 0" class="t-center">
                <p class="color-grey mar-top-4" style="font-size: 0.8em">
                  Keine Ergebnisse gefunden
                </p>
              </div>
            </div>
            <div v-else>
              <div class="t-center">
                <img src="assets/images/loading.gif" class="width-40">
                <p class="color-grey mar-top-4" style="font-size: 0.8em">
                  Lädt
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <transition name="modal" id="OrderDetailsModal" style="position: fixed;">
      <div class="vueModal" v-if="OrderDetailsModal">
        <div class="vueModalOuter">
          <div class="vueModalInner">
            <div>
              <h5 class="t-center">
                Bestelldetails
              </h5>
              <div class="mar-top-16">
                <p class="mar-bottom-16">
                  <b v-if="orderDetails.austausch == 1">Austauschgerät</b><b v-else>Erstgerät</b><br>
                  <b>Grund:</b> {{ orderDetails.reason }}<br>
                  <b>Nachricht Antragsteller:</b> {{ orderDetails.message }}<br>
                  <b>Neues Gerät:</b> {{ deviceName }}<br>

                  <b>Unternehmen:</b> {{ orderDetails.company }}<br>
                  <b>Betroffener Nutzer (Name):</b> {{ orderDetails.name }}<br>
                  <b>Betroffener Nutzer (E-Mail):</b> {{ orderDetails.email }}<br>
                  <b>Kostenstelle:</b> {{ orderDetails.kostenstelle }}<br>
                  <b>Rufnummer:</b> {{ orderDetails.geraetphonenumber }}<br>
                  <b>Kaufdatum Altgerät:</b> {{ orderDetails.contractfrom }}<br><br>

                  <b>Firma:</b> {{ orderDetails.company }}<br>
                  <b>Straße:</b> {{ orderDetails.street }}<br>
                  <b>Hausnr.:</b> {{ orderDetails.hnr }}<br>
                  <b>PLZ:</b> {{ orderDetails.plz }}<br>
                  <b>Stadt:</b> {{ orderDetails.city }}<br>
                  <b>Land:</b> {{ orderDetails.country }}<br>
                  <b>Handelt es sich um eine Privatadresse?:</b> <span v-if="orderDetails.privatadress == 0">Nein</span><span v-else>Ja</span><br>
                  __________________________________________<br>
                  <b>Besteller-Name:</b> {{ orderDetails.contactname }}<br>
                  <b>Besteller-E-Mail: </b>{{ orderDetails.contactemail }}<br>
                </p>
              </div>
            </div>
            <div class="pad-4">
              <button class="button black fluid" @click="OrderDetailsModal = false">
                Schließen
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "Historie",
  title: "Bestellung",
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      myorders: 'loading',
      standOrtleiter: [],
      orderDetails: '',
      OrderDetailsModal: false,
      deviceName: '',
      deleteState: 0,
      searchString: '',
    }
  },
  computed:  {
    ordersFiltered(){
      return this.myorders.filter(a => {
        let result = false
        for (const [key, value] of Object.entries(a)) {
          try {
            if(value.toString().toLowerCase().includes(this.searchString.toString().toLowerCase())) result = true
          }catch (e) {}
        }
        return result
      })
    },
  },
  mounted() {
    this.getOrdersHistorie()
  },
  methods: {
    getOrdersHistorie(){
      this.myorders = 'loading'
      const _this = this
      const fD = new FormData()
      fD.append('deleteState', this.deleteState)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('bestellung/getOrdersHistorie', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.myorders = response.data
              console.log(response.data)
              this.myorders.map(singleOrder=>{
                this.getStandortsLeiter(singleOrder.austausch, singleOrder.kostenstelle, singleOrder.id)
              })
            }
          })
          .catch(e => {console.error(e)})
    },
    loadDetails(id){
      const _this = this
      const fD = new FormData()
      fD.append('id', id)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('bestellung/getOrderById', fD)
          .then((response) => {
            if(response.status === 250){
              _this.orderDetails = response.data
              _this.OrderDetailsModal = true
              _this.getDeviceName(response.data.tarif)
            }
          })
          .catch(e => {console.error(e)})
    },
    getDeviceName(tarif){
      const _this = this
      const fD = new FormData()
      fD.append('tarif', tarif)
      fD.append('data', 'tarifdevice')
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('bestellung/getTarifData', fD)
          .then((response) => {
            if(response.status === 250){
              _this.deviceName = response.data
            }
          })
          .catch(e => {console.error(e)})
    },
    getStandortsLeiter(austausch, kostenstelle, id){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('austausch', austausch)
      fD.append('kostenstelle', kostenstelle)
      this.axios.post('bestellung/getStandortsLeiter', fD)
          .then((response) => {
            this.standOrtleiter.push({id:id, name:response.data.firstname+' '+response.data.lastname+' ('+response.data.email+')'})
            return response.data.firstname+' '+response.data.lastname+' ('+response.data.email+')'
          })
          .catch(e => {console.error(e)})
    },
    SL(orderId){
      const found = this.standOrtleiter.find(({id}) => id === orderId)
      return found.name
    },
    deleteOrder(id,status){
      const _this = this
      const fD = new FormData()
      fD.append('id', id)
      fD.append('status', status)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('bestellung/deleteOrder', fD)
          .then((response) => {
            if(response.status === 250){
              _this.getOrdersHistorie()
            }
          })
          .catch(e => {console.error(e)})
    },
  }
}
</script>

<style scoped>

</style>