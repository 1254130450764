<template>
  <div>
    <main class="content-1400 page-content">
      <div class="pad-8 t-center" v-if="feedback != ''">
        <div v-if="feedback == 'successOrder'">
          <span class="label green">Ihre Bestellung wurde erfolgreich aufgegeben.</span>
        </div>
      </div>
      <div class="grid-12">
        <div class="col-sp-12 col-sd-6 row-sp-12 pad-8">
          <div class="bgcolor-white br-10 pad-24">
            <h2 class="mar-bottom-12 f-normal">
              Hallo {{ user.firstname }} {{ user.lastname }},
            </h2>
            <p class="color-grey">
              {{ welcomeText }}
            </p>
            <div class="pad-8" />
            <div v-if="whitelabel == 0" class="t-right">
              <img src="assets/images/mendo-consult-logo.png" class="width-100">
            </div>
          </div>
        </div>
        <div class="col-sp-12 col-sd-6 row-sp-12 pad-8">
          <section v-if="openorders == 0" class="br-10" style="background-image: url('assets/images/hamburg.jpg'); background-repeat: no-repeat; background-size: cover; background-position: center center;">
            <div class="pad-80" />
            <div class="pad-60" />
          </section>
          <section class="br-10 pad-24 bgcolor-primary color-white" v-else>
            <div class="pad-24" />
            <h4>{{ openorders }} Offene Anfragen</h4>
            <p>Du hast noch offene Anfragen zur Bearbeitung.<br> Klicken Sie auf „Weiter“, um diese zu öffnen.</p>
            <div class="t-right">
              <router-link to="/offenebestellungen" class="button black">
                Weiter
              </router-link>
            </div>
            <div class="pad-24" />
          </section>
        </div>
      </div>
      <div class="grid-12">
        <div class="col-sp-12 col-ld-4 row-sp-12 pad-8">
          <div class="bgcolor-white pad-24 br-10">
            <div class="t-center">
              <svg height="80px" data-name="Layer 1" id="Layer_1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title /><path d="M12.2,9h1.6V6.8H16V5.2H13.8V3H12.2V5.2H10V6.8h2.2ZM20,5v5.5L7.45,12.72,5,3H1.25a1,1,0,0,0,0,2H3.47L6.7,18H20V16H8.26l-.33-1.33L22,12.18V5ZM7,19a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,7,19Zm12,0a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,19,19Z" /></svg>
              <h4 class="mar-top-8">
                Bestellung
              </h4>
              <h6 class="f-normal color-grey">
                Neues Gerät anfordern
              </h6>
              <div class="pad-16">
                <router-link to="/bestellung" class="button primary fluid">
                  Weiter
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sp-12 col-ld-4 row-sp-12 pad-8">
          <div class="bgcolor-white pad-24 br-10">
            <div class="t-center">
              <svg class="feather feather-tool" fill="none" height="80px" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" /></svg>
              <h4 class="mar-top-8">
                Technischer Support
              </h4>
              <h6 class="f-normal color-grey">
                Bei technischen Problemen
              </h6>
              <div class="pad-16">
                <button class="button primary fluid" @click="TechnischerSupportModal = true">
                  Weiter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sp-12 col-ld-4 row-sp-12 pad-8">
          <div class="bgcolor-white pad-24 br-10">
            <div class="t-center">
              <svg height="80px" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg"><path d="M208 0C322.9 0 416 78.8 416 176C416 273.2 322.9 352 208 352C189.3 352 171.2 349.7 153.9 345.8C123.3 364.8 79.13 384 24.95 384C14.97 384 5.93 378.1 2.018 368.9C-1.896 359.7-.0074 349.1 6.739 341.9C7.26 341.5 29.38 317.4 45.73 285.9C17.18 255.8 0 217.6 0 176C0 78.8 93.13 0 208 0zM164.6 298.1C179.2 302.3 193.8 304 208 304C296.2 304 368 246.6 368 176C368 105.4 296.2 48 208 48C119.8 48 48 105.4 48 176C48 211.2 65.71 237.2 80.57 252.9L104.1 277.8L88.31 308.1C84.74 314.1 80.73 321.9 76.55 328.5C94.26 323.4 111.7 315.5 128.7 304.1L145.4 294.6L164.6 298.1zM441.6 128.2C552 132.4 640 209.5 640 304C640 345.6 622.8 383.8 594.3 413.9C610.6 445.4 632.7 469.5 633.3 469.9C640 477.1 641.9 487.7 637.1 496.9C634.1 506.1 625 512 615 512C560.9 512 516.7 492.8 486.1 473.8C468.8 477.7 450.7 480 432 480C350 480 279.1 439.8 245.2 381.5C262.5 379.2 279.1 375.3 294.9 369.9C322.9 407.1 373.9 432 432 432C446.2 432 460.8 430.3 475.4 426.1L494.6 422.6L511.3 432.1C528.3 443.5 545.7 451.4 563.5 456.5C559.3 449.9 555.3 442.1 551.7 436.1L535.9 405.8L559.4 380.9C574.3 365.3 592 339.2 592 304C592 237.7 528.7 183.1 447.1 176.6L448 176C448 159.5 445.8 143.5 441.6 128.2H441.6z" /></svg>
              <h4 class="mar-top-8">
                Allgemeiner Support
              </h4>
              <h6 class="f-normal color-grey">
                Bei Allgemeinen und Vertragsthemen
              </h6>
              <div class="pad-16">
                <button class="button primary fluid" @click="SupportModal = true">
                  Weiter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <transition name="modal" id="TechnischerSupportModal" style="position: fixed;">
      <div class="vueModal" v-if="TechnischerSupportModal">
        <div class="vueModalOuter">
          <div class="vueModalInner">
            <div>
              <technischerSupport />
            </div>
            <div class="pad-4">
              <button class="button black fluid" @click="TechnischerSupportModal = false">
                Schließen
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="modal" id="SupportModal" style="position: fixed;">
      <div class="vueModal" v-if="SupportModal">
        <div class="vueModalOuter">
          <div class="vueModalInner">
            <div>
              <support />
            </div>
            <div class="pad-4">
              <button class="button black fluid" @click="SupportModal = false">
                Schließen
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {aSyncData} from "@/modules/main";
import technischerSupport from "@/components/basic/technischerSupport";
import support from "@/components/basic/support";

export default {
  name: "dashboardView",
  components: {
    technischerSupport: technischerSupport,
    support: support,
  },
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      portale: '',
      welcomeText: '',
      whitelabel: 1,
      TechnischerSupportModal: false,
      SupportModal: false,
      openorders: 0,
      feedback: '',
    }
  },
  mounted() {
    this.test()
    this.getPortalData()
    this.getOpenOrders()
    this.checkFeedback()

  },
  methods: {
    test(){
      console.log(this.auth)
      console.log(this.user.firstname)
    },
    checkFeedback(){
      if(this.$route.query.order){this.feedback = 'successOrder';}
    },
    getPortalData(){
      const _this = this
      const fD = new FormData()
      fD.append('data', 'welcomettext')
      this.axios.post('dashboard/getData', fD)
          .then((response) => {
            _this.welcomeText = response.data
          })
          .catch(e => {console.error(e)})
      fD.append('data', 'whitelabel')
      this.axios.post('dashboard/getData', fD)
          .then((response) => {
            _this.whitelabel = response.data
          })
          .catch(e => {console.error(e)})
    },
    getOpenOrders(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('bestellung/getOpenOrders', fD)
          .then((response) => {
            if(response.status === 250){
              _this.openorders = response.data.length
            }
          })
          .catch(e => {console.error(e)})
    },
  }
}
</script>

<style scoped>

</style>