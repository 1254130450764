/* eslint-disable */
import { defineStore } from 'pinia'
import axios from 'axios'
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {aSyncData} from "@/modules/main";

const error = new ErrorEvent('doof')

export const authStore = defineStore('auth', {
    state: () => ({
        isAuth: false,
        nodeToken: null,
        cookie:  localStorage.getItem('cookie'),
        sessionID: localStorage.getItem('sessionID'),
        lastActivity: 0
    }),
    actions: {

        logout() {
            const _this = this
            const user = userStore()
            const config = configStore()

            axios.post(config.projectURL + 'login/logout')
                .then(function (response) {
                    if (response.status === 250) {
                        localStorage.clear()
                        sessionStorage.clear()
                        localStorage.setItem('darkMode', JSON.stringify(config.darkMode))

                        user.$reset()
                        _this.$reset()

                        const cookies = document.cookie.split(';')

                        for (var i = 0; i < cookies.length; i++) {
                            const cookie = cookies[i]
                            const eqPos = cookie.indexOf('=')
                            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
                            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
                        }
                        _this.$router.push('/')
                    }
                })
                .catch((e) => {
                    console.error('doof', e)
                })
        },

        update() {
            const _this = this
            const user = userStore()
            const config = configStore()
            return new Promise(function (resolve, reject) {
                if (_this.isAuth && (_this.lastActivity + 60000) > Date.now()) {
                    resolve()
                }
                else if (_this.sessionID != null) {
                    _this.lastActivity = Date.now()
                    const fD = new FormData()
                    //BEHEBUNG
                    fD.append('sessionID', _this.sessionID)
                    axios.post(config.projectURL + 'login/update', fD)
                        .then((response) => {
                            if (response.status === 250){
                                const data = response.data
                                _this.saveData(data)
                                resolve(response.status)
                            }else{
                                _this.sessionID = null
                                localStorage.removeItem('sessionID')
                                _this.cookieLogin()
                                    .then(() => {
                                        _this.isAuth = true
                                        resolve()
                                    })
                                    .catch((e) => {
                                        _this.isAuth = false
                                        reject(error)
                                    })
                            }
                        })
                        .catch((e) => {
                            reject(e)
                        })
                }
                else if(_this.cookie != null){
                    _this.cookieLogin()
                        .then(() => {
                            _this.isAuth = true
                            resolve()
                        })
                        .catch((e) => {
                            _this.isAuth = false
                            reject(e)
                        })
                }else{
                    reject(error)
                }
            })
        },

        saveData(data){
            const config = configStore()
            const user = userStore()

            user.id = data.userId
            user.email = data.email
            user.firstname = data.firstname
            user.lastname = data.lastname
            user.role = data.role
            user.company = data.company
            user.phone = data.phone
            user.kostenstelle = data.kostenstelle
            user.contractfrom = data.contractfrom
            user.zipcode = data.zipcode
            user.city = data.city
            user.street = data.street
            user.houseno = data.houseno
            user.devicename = data.devicename
            user.devicenumber = data.devicenumber
            user.devicetype = data.devicetype
            user.imei = data.imei

            config.darkMode = data.darkMode
            localStorage.setItem('sessionID', data.sessionId)
            this.sessionID = data.sessionID
            this.nodeToken = data.nodeToken
            this.isAuth = true
            this.lastActivity = Date.now()
        },

        cookieLogin () {
            const _this = this
            return new Promise(function (resolve, reject) {
                const config = configStore()
                const user = userStore()
                try {
                    if (_this.cookie != null) {
                        const fD = new FormData()
                        fD.append('cookie', _this.cookie)
                        axios.post(config.projectURL + 'login/cookie', fD)
                            .then((response) => {
                                if (response.status == 250) {
                                    const data = response.data
                                    _this.saveData(data)
                                    resolve(1);
                                } else {
                                    _this.$reset()
                                    user.$reset()
                                    localStorage.removeItem('sessionID')
                                    localStorage.removeItem('cookie')
                                    reject(error)
                                }
                            })
                            .catch(e => {
                                reject(e)
                            })
                    }else{
                        reject(error)
                    }
                } catch (e) {
                    reject(error)
                }
            })
        },
    }
})