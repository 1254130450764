<template>
  <div>
    <main id="profil" class="content-1400 page-content">
      <div class="pad-4">
        <section id="profilheader" class="bgcolor-white br-10">
          <div class="grid-12">
            <div class="col-sp-12 col-tb-3 col-sd-2 row-sp-12">
              <img :src="config.projectURL + 'assets/images/users/avatar_default.png'" class="image width-150 circular">
            </div>
            <div class="col-sp-12 col-tb-9 col-sd-10 row-sp-12">
              <div class="profilheaderInfos">
                <div class="grid-12">
                  <div class="col-sp-12 col-tb-8 row-sp-12 pad-16">
                    <h2 class="f-bold color-black">
                      {{ user.firstname }} {{ user.lastname }}
                    </h2>
                    <h4 class="f-normal color-primary">
                      {{ user.email }}
                    </h4>
                  </div>
                  <div class="col-sp-12 col-tb-4 row-sp-12 t-right pad-16" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="grid-12">
        <div class="col-sp-12 row-sp-12 pad-4">
          <section>
            <div class="bgcolor-white mar-top-16 br-10 t-left pad-16 grid-12">
              <section class="col-sp-12 col-tb-6 row-sp-12 pad-8">
                <h3 class="mar-bottom-16">
                  Kontaktdaten
                </h3>
                <table style="width: 100%;">
                  <tr>
                    <td><b>Vorname:</b></td>
                    <td>{{ user.firstname }}</td>
                  </tr>
                  <tr>
                    <td><b>Nachname:</b></td>
                    <td>{{ user.lastname }}</td>
                  </tr>
                  <tr>
                    <td><b>E-Mail:</b></td>
                    <td>{{ user.email }}</td>
                  </tr>
                  <tr>
                    <td><b>Telefonnummer:</b></td>
                    <td>{{ user.phone }}</td>
                  </tr>
                  <tr>
                    <td><b>Firma:</b></td>
                    <td>
                      {{ user.company }}
                    </td>
                  </tr>
                  <tr>
                    <td><b>Kostenstelle:</b></td>
                    <td>{{ user.kostenstelle }}</td>
                  </tr>
                  <tr>
                    <td><b>Adresse:</b><br></td>
                    <td>{{ user.steet }} {{ user.houseno }} <br> {{ user.plz }} {{ user.city }}</td>
                  </tr>
                </table>
              </section>
              <section class="col-sp-12 col-tb-6 row-sp-12 pad-8">
                <h3 class="mar-bottom-16">
                  Meine Geräte
                </h3>
                <div v-if="user.devicename != null && user.devicename != ''">
                  <div>
                    <div class="content">
                      <h6 class="f-normal">
                        [{{ user.devicetype }}] {{ user.devicename }} {{ user.devicenumber }}
                      </h6>
                      <div class="color-grey">
                        <p>
                          IMEI: {{ user.imei }}<br>
                          Kaufdatum: {{ user.contractfrom }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="t-center">
                  <p style="color: rgba(0,0,0,0.4);" class="mar-bottom-16">
                    Keine Geräte gefunden
                  </p>
                  <router-link to="/bestellung" class="button tiny primary">
                    Gerät anfordern
                  </router-link>
                </div>
              </section>
            </div>
          </section>
          <div class="pad-4" />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
/* eslint-disable */
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";


export default {
  name: "Profil",
  title: "Profil",
  components: {
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {

    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
  }
}
</script>

<style scoped>

main#profil{
  padding-bottom: 80px;
  padding-top: 120px!important;
}
</style>
<style>
#profilheader{
  text-align: center;
  border-radius: 10px;
}
#profilheader img{
  border: 4px solid var(--theme-mendo-primary);
  position: relative;
  z-index: 1;
  margin-left: 32px;
  margin-top: -60px;
}
#profilheader .profilheaderInfos{
  text-align: left;
  padding-bottom: 16px;

}
#profilheader .profilheaderInfos h2{
  font-size: 2em;
  margin: 0;
}
#profilheader .profilheaderInfos h4{
  font-size: 1.2em;
}
#helpcontainer p{
  margin-top: 8px;
  font-size: 0.9em;
}
#profil section h3{
  font-size: 1.4em;
}

</style>