<template>
  <div>
    <pre>
      {{ sessionData }}
    </pre>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "debug",
  title: "debug",
  components:{

  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      sessionData: '',
    }
  },
  mounted() {
    this.config.loading = false
    this.getSession()
  },
  methods: {
    getSession(){
      const _this = this
      const fD = new FormData()
      this.axios.post('login/getSession', fD)
          .then((response) => {
            _this.sessionData = response.data
          })
          .catch(e => {console.error(e)})
    },
  }
}
</script>

<style scoped>
.login{
  padding-top: 20vh;
}
.login .field.simple input{
  max-width: 300px;
}

.forgotpassword a{
  font-size: 0.7em;
  text-decoration: underline;
  cursor: pointer;
}
.rememberme span{
  font-size: 0.8em;
}
.divider{
  margin: 0 auto;
  display: block;
  max-width: 300px;
  width: 100%;
  text-align: center;
  padding: 8px 0;
}
.divider:before,
.divider:after{
  content: ' ---------- ';
  color: rgba(0,0,0,0.4);
}
#LoginButton{
  margin: 0 auto;
  display: block;
  max-width: 300px;
  width: 100%;
}

.feedbackmessage{
  font-size: 0.8em;
}
.width-300{
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}
.field.simple label{
  font-size: 0.7em;
  font-weight: normal;
  display: inline;
  vertical-align: 2px;
  padding-left: 4px;
}
</style>