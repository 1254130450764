import { defineStore } from 'pinia'
import {aSyncData} from "@/modules/main";

export const userStore = defineStore('user', {
    state: () => ({
        id: -1,
        firstname: '',
        lastname: '',
        email: '',
        role: 0,
        company: '',
        phone: '',
        kostenstelle: '',
        contractfrom: '',
        zipcode: '',
        city: '',
        street: '',
        houseno: '',
        devicename: '',
        devicenumber: '',
        devicetype: '',
        imei: '',

    }),
    actions: {

    }
})