<template>
  <div>
    <main class="content-500">
      <h4 class="t-center mar-bottom-16 mar-top-16">
        Benachrichtigungen
      </h4>
      <div v-for="notification in notifications" :key="notification.id" class="pad-8">
        <a :href="notification.link" style="display: block; text-decoration: none; color: inherit;" :class="[notification.new == 1 ? 'new' : '']" class="notification bgcolor-white br-10 pad-16">
          <div class="grid-12">
            <div class="col-sp-2 row-sp-12">
              <svg width="40px" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title data-v-132370e2="" /><path data-v-132370e2="" d="M18 15.984l2.016 2.016v0.984h-16.031v-0.984l2.016-2.016v-4.969c0-3.094 1.641-5.625 4.5-6.328v-0.703c0-0.844 0.656-1.5 1.5-1.5s1.5 0.656 1.5 1.5v0.703c2.859 0.703 4.5 3.281 4.5 6.328v4.969zM12 21.984c-1.125 0-2.016-0.891-2.016-1.969h4.031c0 1.078-0.938 1.969-2.016 1.969z" /></svg>
            </div>
            <div class="col-sp-10 row-sp-12 pad-left-8">
              <p style="font-size: 0.9em;" class="color-grey">{{ notification.text }}</p>
              <p class="t-right color-primary mar-top-8" style="font-size: 0.8em;">
                {{ renderTimestampForJS(notification.timestamp) }}
              </p>
            </div>
          </div>
        </a>
      </div>
    </main>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "benachrichtigungen",
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      notifications: ''
    }
  },
  mounted() {
    this.getNotifications()
  },
  methods: {
    getNotifications(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('notifications/getNotifications', fD)
          .then((response) => {
            _this.notifications = response.data
            _this.readNotifications()
          })
          .catch(e => {console.error(e)})
    },
    readNotifications(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('notifications/readNotifications', fD)
          .then((response) => {})
          .catch(e => {console.error(e)})
    },
    renderTimestampForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0] + ' ' + jsTime[0] + ':' + jsDate[1] + ' Uhr'
    },
  }
}
</script>

<style scoped>
.notification{
  border: 2px solid transparent;
}
.notification.new{
  border: 2px solid var(--theme-mendo-primary);
}
.notification svg{
  opacity: 0.4;
}
</style>