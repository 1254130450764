import { createRouter, createWebHistory } from 'vue-router'

import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";

import login from "@/views/loginView";
import debug from "@/views/debug";

import navBar from "@/components/navBar/navBar";
import dashboardView from "@/views/dashboardView";
import benachrichtigungen from "@/views/benachrichtigungen";
import profil from "@/views/profil";
import bestellungNeworder from "@/views/bestellung/neworder";
import bestellungMyorders from "@/views/bestellung/myorders";
import bestellungOpenorders from "@/views/bestellung/openorders";
import bestellungHistorie from "@/views/bestellung/historie";

const routes = [
  {
    path: '/',
    alias: '/login',
    name: 'Login',
    components: {
      default: login
    },
    meta: { noAuth: true },
  },
  {
    path: '/',
    alias: '/debug',
    name: 'Debug',
    components: {
      default: debug
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    components: {
      navBar: navBar,
      default: dashboardView
    },
    meta: { auth: true },
  },
  {
    path: '/bestellung',
    name: 'Bestellung',
    components: {
      navBar: navBar,
      default: bestellungNeworder
    },
    meta: { auth: true },
  },
  {
    path: '/meinebestellungen',
    name: 'NewOrder',
    components: {
      navBar: navBar,
      default: bestellungMyorders
    },
    meta: { auth: true },
  },
  {
    path: '/bestellhistorie',
    name: 'Historie',
    components: {
      navBar: navBar,
      default: bestellungHistorie
    },
    meta: { auth: true },
  },
  {
    path: '/offenebestellungen',
    name: 'Openorders',
    components: {
      navBar: navBar,
      default: bestellungOpenorders
    },
    meta: { auth: true },
  },
  {
    path: '/profil',
    name: 'Profil',
    components: {
      navBar: navBar,
      default: profil
    },
    meta: { auth: true },
  },
  {
    path: '/benachrichtigungen',
    name: 'Benachrichtigungen',
    components: {
      navBar: navBar,
      default: benachrichtigungen
    },
    meta: { auth: true },
  },
  {
    name: '404',
    path: '/:pathMatch(.*)*',
    alias: '/404',
    redirect: () => {
      return { path: '/' }
    },
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const auth = authStore()
  const config = configStore()

  window.scrollTo(0, 0)
  window.onscroll = function () {}

  if (to.matched.some(record => record.meta.auth)) {
    auth.update()
        .then(r => {
          config.loading = true
          next()
        })
        .catch(e => {
          console.error(e)
          next({
            path: '/login',
            query: { redirect: encodeURI(to.fullPath) }
          })
        })
  } else if (to.matched.some(record => record.meta.noAuth)) {
    auth.update()
        .then(() => {
          next({
            name: 'Dashboard'
          })
        })
        .catch((e)=> {
          next()
        })
        .finally(function () {
          config.loading = true
        })
  } else {
    auth.update()
        .catch(e =>{
        })
        .finally(() => {
          config.loading = true
          next()
        })
  }
})

let history = []
router.afterEach((to, from) => {
  if(to.path === history.at(-2)?.path){
    to.meta.transition = history.at(-2).direction === 'slide-right' ? 'slide-left' : 'slide-right'
    history.pop()
  }else{
    to.meta.transition = to.path.split('/').length < from.path.split('/').length ? 'slide-right' : 'slide-left'
    history.push({path: to.path, direction: to.meta.transition})
  }
})

export default router