<template>
  <div>
    <form v-if="feedback == false" method="post" @submit.prevent="sendMessage()">
      <h4 class="pad-4 t-center">
        Support
      </h4>
      <p class="pad-4 mar-bottom-16 color-grey">
        {{ supporttext }}
      </p>
      <div class="pad-4">
        <div class="field">
          <label>Kategorie:</label>
          <select name="category" required v-model="category">
            <option value="" disabled selected style="color: rgba(191,191,191,.87);">
              Kategorie
            </option>
            <option value="Tarif Fragen">
              Tarif Fragen
            </option>
            <option value="Fragen zu Endgeräten">
              Fragen zu Endgeräten
            </option>
            <option value="Technische Beratung / Fragen">
              Technische Beratung / Fragen
            </option>
            <option value="Vertragsänderungen">
              Vertragsänderungen
            </option>
            <option value="Vertragskündigung">
              Vertragskündigung
            </option>
            <option value="Sonstiges">
              Sonstiges
            </option>
          </select>
        </div>
      </div>
      <div class="pad-4">
        <div class="field">
          <label>Ihr Anliegen:</label>
          <textarea rows="2" v-model="message" placeholder="Wie können wir dir helfen?" required />
        </div>
      </div>
      <h5 class="t-center pad-8">
        Kontaktdaten
      </h5>
      <div class="grid-12 pad-4">
        <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
          <div class="field">
            <label>Name:</label>
            <input type="text" name="name" placeholder="Name" required v-model="name">
          </div>
        </div>
        <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
          <div class="field">
            <label>Telefonnummer:</label>
            <input type="tel" name="phonenumber" placeholder="Telefonnummer" required v-model="phonenumber">
          </div>
        </div>
        <div class="col-sp-12 row-sp-12 pad-4">
          <div class="field">
            <label>E-Mail:</label>
            <input type="email" name="email" placeholder="E-Mail" required v-model="email">
          </div>
        </div>
      </div>
      <div class="pad-8" />
      <div class="pad-4">
        <button class="button primary fluid" type="submit">
          Nachricht senden
        </button>
      </div>
    </form>
    <div v-else>
      <div class="t-center pad-16">
        <h3>
          Ihre Supportanfrage wurde versendet
        </h3>
        <h4 class="f-normal color-grey">
          <p>Wir werden uns schnellstmöglich um Ihr Anliegen kümmern.</p>
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "technischerSupport",
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      supporttext: '',
      category: '',
      message: '',
      name: this.user.firstname+' '+this.user.lastname,
      phonenumber: '',
      email: this.user.email,
      feedback: false,

    }
  },
  mounted() {
    this.getText();
  },
  methods:{
    getText(){
      const _this = this
      const fD = new FormData()
      fD.append('data', 'asupporttext')
      this.axios.post('dashboard/getData', fD)
          .then((response) => {
            _this.supporttext = response.data
          })
          .catch(e => {console.error(e)})
    },
    sendMessage(){
      const _this = this
      const fD = new FormData()
      fD.append('category', this.category)
      fD.append('message', this.message)
      fD.append('name', this.name)
      fD.append('email', this.email)
      fD.append('phonenumber', this.phonenumber)
      this.axios.post('dashboard/support', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              this.category = ''
              this.message = ''
              this.name = ''
              this.phonenumber = ''
              this.email = ''
              _this.feedback = true
            }
          })
          .catch(e => {console.error(e)})
    }
  }
}
</script>

<style scoped>

</style>