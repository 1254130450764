import * as Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import title from '@/mixins/title'
import { createPinia } from 'pinia'
import vue3GoogleLogin from 'vue3-google-login'

import {configStore} from "@/store/configStore";
import modal from "@/components/basic/modal";

const pinia = createPinia();
pinia.use(({ store }) => {
  store.$router = Vue.markRaw(router)
});

const vueProto = Vue.createApp(App)
    .use(pinia)
    .use(router)
    .use(VueAxios, axios)
    .use(vue3GoogleLogin, {
      clientId: '947669320509-gbft6hgbjt8micafmdv6confvf66hav2.apps.googleusercontent.com'
    })
    //.use(i18n)
    .mixin(title)

const config = configStore()
vueProto.axios.defaults.baseURL = config.projectURL
vueProto.config.warnHandler = () => null;
vueProto.component('VueModal', modal)

router.isReady().then(() => vueProto.mount('#App'))
